import * as React from 'react';
import { Link } from 'gatsby';

// import styles from './index.module.css';
import { user, avatar, description, username, themename, excerpt, button, button2,  } from './index.module.css';

import {
  AboutWrapper,
} from './style';



const User = props => (
<div>
  <Link to={props.url}>
    <div className={user}>
        <img src={props.avatar} className={avatar} alt="" />
      <div className={description}>
        <h3 className={username}><span className={themename}>{props.themename}</span>{props.username}</h3>
        <p className={excerpt}>{props.excerpt}</p>
      </div>
    </div>
  </Link>
</div>
)

const Jisyo = props => (
<div style={{width:"25%"}}>
  <a href={"/tags/"+props.title}>
    <div className={button2}>
      <img style={{width:"20px",margin:"2px 5px 2px 2px",}} src={props.src}/>
       {props.title}
    </div>
  </a>
</div>
)

const Jisyo2 = props => (
<div style={{width:"25%"}}>
  {/* <a href={"/tags/"+props.title}> */}
    <div className={button2}>
      <img style={{width:"20px",margin:"2px 5px 2px 2px",}} src={props.src}/>
       {props.title}
    </div>
  {/* </a> */}
</div>
)




const useStateWithLocalStorage = localStorageKey => {
  const [value, setValue] = React.useState(
    typeof window !== 'undefined' && window.localStorage.getItem(localStorageKey)
    || ''
  );
 
  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      localStorage.setItem(localStorageKey, value);
    }
  }, [value]);
 
  return [value, setValue];
};

 
const App = () => {
  const [value, setValue] = useStateWithLocalStorage(
    'cityset1'
  );
 
  const onChange = event => setValue(event.target.value);
 
  return (
    <AboutWrapper>

<h1>
テーマ選択：「見える化」したいテーマを選びましょう
</h1>
<hr/>
<div>
    <div className={user}>
      <div>
        <img src="/01SDGs.jpg" alt="image01" className={avatar} style={{width:"250px"}}/>
      </div>
      <div className={description}>
        {/* <Link to="/sdgs"> */}
          <h3 className={username}><span className={themename}>SDGs</span></h3>
        {/* </Link> */}

        <img style={{width:"10%",margin:"2px"}} src="/sdgsicon/sdg_01.png"/>
        <img style={{width:"10%",margin:"2px"}} src="/sdgsicon/sdg_02.png"/>
        <a href="/tags/すべての人に健康と福祉を"><img style={{width:"10%",margin:"2px"}} src="/sdgsicon/sdg_03.png"/></a>
        <a href="/tags/人や国の不平等をなくそう"><img style={{width:"10%",margin:"2px"}} src="/sdgsicon/sdg_04.png"/></a>
        <a href="/tags/ジェンダー平等を実現しよう"><img style={{width:"10%",margin:"2px"}} src="/sdgsicon/sdg_05.png"/></a>
        <img style={{width:"10%",margin:"2px"}} src="/sdgsicon/sdg_06.png"/>
        <img style={{width:"10%",margin:"2px"}} src="/sdgsicon/sdg_07.png"/>
        <img style={{width:"10%",margin:"2px"}} src="/sdgsicon/sdg_08.png"/>
        <a href="/tags/産業と技術革新の基盤をつくろう"><img style={{width:"10%",margin:"2px"}} src="/sdgsicon/sdg_09.png"/></a>
        <a href="/tags/sdgs-10"><img style={{width:"10%",margin:"2px"}} src="/sdgsicon/sdg_10.png"/></a>
        <a href="/tags/住み続けられるまちづくりを"><img style={{width:"10%",margin:"2px"}} src="/sdgsicon/sdg_11.png"/></a>
        <img style={{width:"10%",margin:"2px"}} src="/sdgsicon/sdg_12.png"/>
        <img style={{width:"10%",margin:"2px"}} src="/sdgsicon/sdg_13.png"/>
        <img style={{width:"10%",margin:"2px"}} src="/sdgsicon/sdg_14.png"/>
        <img style={{width:"10%",margin:"2px"}} src="/sdgsicon/sdg_15.png"/>
        <img style={{width:"10%",margin:"2px"}} src="/sdgsicon/sdg_16.png"/>
        <img style={{width:"10%",margin:"2px"}} src="/sdgsicon/sdg_17.png"/>
        <img style={{width:"10%",margin:"2px"}} src="/sdgsicon/sdg_all.png"/>

      </div>
    </div>
</div>




<div>
    <div className={user}>
    <img src="/02chirisougou.jpg" alt="image01" className={avatar} style={{width:"250px"}}/>
      <div className={description}>
        {/* <Link to="/chirisogo"> */}
          <h3 className={username}><span className={themename}>高校地理総合</span></h3>
        {/* </Link> */}

        <div style={{display:"flex"}}>
          <div className={button}>
            {/* <a href="/tags/地図や地理情報システム"> */}
              地図や地理情報システム
            {/* </a> */}
          </div>
          <div className={button}>
            <a href="/tags/地球的課題と国際協力">
              地球的課題と国際協力
            </a>
          </div>
        </div>
        <div style={{display:"flex"}}>
          <div className={button}>
            {/* <a href="/chirisogo"> */}
              結びつきを深める現代世界
            {/* </a> */}
          </div>
          <div className={button}>
            {/* <a href="/chirisogo"> */}
              自然環境と防災
            {/* </a> */}
          </div>
        </div>
        <div style={{display:"flex"}}>
          <div className={button}>
            {/* <a href="/chirisogo"> */}
              生活文化の多様性と国際理解
            {/* </a> */}
          </div>
          <div className={button}>
            <a href="/tags/生活圏の調査と地域の展望">
              生活圏の調査と地域の展望
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>


<div>
    <div className={user}>
    <img src="/03chiritankyu.jpg" alt="image01" className={avatar} style={{width:"250px"}}/>
      <div className={description}>
        {/* <Link to="/chiritankyu"> */}
          <h3 className={username}><span className={themename}>高校地理探究</span></h3>
        {/* </Link> */}

        <div style={{display:"flex"}}>
          <div className={button}>
            {/* <a href="/chiritankyu"> */}
              自然環境
            {/* </a> */}
          </div>
          <div className={button}>
            <a href="/tags/生活文化，民族・宗教">
              生活文化，民族・宗教
            </a>
          </div>
        </div>
        <div style={{display:"flex"}}>
          <div className={button}>
            {/* <a href="/chiritankyu"> */}
              資源，産業
            {/* </a> */}
          </div>
          <div className={button}>
            {/* <a href="/chiritankyu"> */}
              現代世界の地域区分
            {/* </a> */}
          </div>
        </div>
        <div style={{display:"flex"}}>
          <div className={button}>
            <a href="/tags/交通・通信，観光">
              交通・通信，観光
            </a>
          </div>
          <div className={button}>
            {/* <a href="/chiritankyu"> */}
              現代世界の諸地域
            {/* </a> */}
          </div>
        </div>
        <div style={{display:"flex"}}>
          <div className={button}>
            <a href="/tags/人口，都市・村落">
              人口，都市・村落
            </a>
          </div>
          <div className={button}>
            {/* <a href="/chiritankyu"> */}
              持続可能な国土像の探究
            {/* </a> */}
          </div>
        </div>
      </div>
    </div>
  </div>



<div>
    <div className={user}>
    <img src="/04chiritekijishou.jpg" alt="image01" className={avatar} style={{width:"250px"}}/>
      <div className={description}>
        {/* <Link to="/chirijisyo"> */}
          <h3 className={username}><span className={themename}>地理的事象</span></h3>
        {/* </Link> */}

        <div style={{display:"flex"}}>
          <Jisyo title="人口" src="/icon_svg_0311/06_zinkou.svg"/>
          <Jisyo2 title="民族" src="/icon_svg_0311/07_minzoku.svg"/>
          <Jisyo2 title="国際機関" src="/icon_svg_0311/19_kokusai.svg"/>
          <Jisyo2 title="世界の国々" src="/icon_svg_0311/20_sekainokuniguni.svg"/>
        </div>
        <div style={{display:"flex"}}>
          <Jisyo2 title="地形" src="/icon_svg_0311/01_tikei.svg"/>
          <Jisyo2 title="気候" src="/icon_svg_0311/02_kikou.svg"/>
          <Jisyo2 title="環境問題" src="/icon_svg_0311/03_tikyuukannkyou.svg"/>
          <Jisyo title="労働" src="/icon_svg_0311/15_roudou.svg"/>
        </div>
        <div style={{display:"flex"}}>
          <Jisyo2 title="農業" src="/icon_svg_0311/08_nougyou.svg"/>
          <Jisyo2 title="林業" src="/icon_svg_0311/09_ringyou.svg"/>
          <Jisyo2 title="水産業" src="/icon_svg_0311/10_suisangyou.svg"/>
          <Jisyo title="交通" src="/icon_svg_0311/04_koutuu.svg"/>
        </div>
        <div style={{display:"flex"}}>
          <Jisyo2 title="工業" src="/icon_svg_0311/13_kougyou.svg"/>
          <Jisyo2 title="鉱業" src="/icon_svg_0311/12_kougyou.svg"/>
          <Jisyo2 title="資源エネルギー" src="/icon_svg_0311/11_sigenn_enerugi.svg"/>
          <Jisyo2 title="通信" src="/icon_svg_0311/05_tuusin.svg"/>
        </div>
        <div style={{display:"flex"}}>
          <Jisyo title="第３次産業" src="/icon_svg_0311/14_dai3zisangyou.svg"/>
          <Jisyo2 title="観光" src="/icon_svg_0311/16_kankou.svg"/>
          <Jisyo2 title="経済" src="/icon_svg_0311/17_keizai.svg"/>
          <Jisyo2 title="貿易" src="/icon_svg_0311/18_boueki.svg"/>
        </div>

      </div>
    </div>
  </div>




  <div>
    <div className={user}>
    <img src="/05shutten.jpg" alt="image01" className={avatar} style={{width:"250px"}}/>
      <div className={description}>
        {/* <Link to="/chiritankyu"> */}
          <h3 className={username}><span className={themename}>出典</span></h3>
        {/* </Link> */}

        <div style={{display:"flex"}}>
          <div className={button}>
            <a href="/tags/国勢調査">
              国勢調査
            </a>
          </div>
          <div className={button}>
            <a href="/tags/商業統計調査">
              商業統計調査
            </a>
          </div>
        </div>
        <div style={{display:"flex"}}>
          <div className={button}>
            <a href="/tags/経済センサス">
              経済センサス
            </a>
          </div>
          <div className={button}>
            <a href="/tags/国土数値情報">
              国土数値情報
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>



{/* 
      <User
        url="/sdgs"
        themename="SDGs"
        username="のテーマから選ぶ"
        avatar="https://source.unsplash.com/featured/250x150/?sdgs"
        excerpt="持続可能な開発目標（SDGs）とは，2015年9月の国連サミットで採択された「持続可能な開発のための2030アジェンダ」にて記載された2030年までに持続可能でよりよい世界を目指す国際目標です。"
      />
      <User
        url="/chirisogo"
        themename="高校地理総合"
        username="の単元から選ぶ"
        avatar="https://source.unsplash.com/featured/250x150/?map"
        excerpt="概念などを活用して多面的・多角的に考察したり、地理的な課題の解決に向けて構想したりする学習過程を前提に、世界の生活文化の多様性や、防災、地域や地球的課題への取組などを理解する。"
      />
      <User
        url="/chiritankyu"
        themename="高校地理探究"
        username="の単元から選ぶ"
        avatar="https://source.unsplash.com/featured/250x150/?geography"
        excerpt="「地理探究」は、「地理総合」で身に付けた学習の成果を活用し、現実を踏まえての主題追究、より深い理解と課題の探究を目指す教科である。"
      />
      <User
        url="/chiritankyu"
        themename="地理的事象"
        username="の分類から選ぶ"
        avatar="https://source.unsplash.com/featured/250x150/?geographic"
        excerpt="「地理探究」は、「地理総合」で身に付けた学習の成果を活用し、現実を踏まえての主題追究、より深い理解と課題の探究を目指す教科である。"
      /> */}
      {/* <User
        url="/sdgs"
        themename="出典"
        username="から選ぶ"
        avatar="https://source.unsplash.com/featured/250x150/?book"
        excerpt="出典から選べます。"
      /> */}
      {/* <User
        url="/sdgs"
        themename="新しい順"
        username="から選ぶ"
        avatar="https://source.unsplash.com/featured/250x150/?new"
        excerpt="新しい順から選べます。"
      /> */}

    </AboutWrapper>

  );
};
 
export default App;



